import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        OfficeConduct is <br/>
        <span className="underline"> a UX/UI strategy firm based in Berlin.</span>
      </header>
      <hr/>
      <div className="info">
        For more information contact:<br/><br/> contact@officeconduct.com<br/>
        Choriner Str 7<br/>
        Berlin, 10119<br/>
        Germany <br/>
      </div>
    </div>
  );
}

export default App;
